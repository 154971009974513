.App {
  /*text-align: center;*/
}

.App-logo {
  width: 192px;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  mheight: 100px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  color: #40676e;
  font-weight: bold;
}

.fassbender-loading {
    -webkit-animation: scaling 2s,;
    animation: scaling 2s;
    -webkit-transform: scale(1,1);
    transform: scale(1,1);
    transform: rotate(0deg);
    animation-iteration-count: infinite;
    transition-timing-function: ease-in-out;
}

@-webkit-keyframes scaling {
    50% {
        -webkit-transform: scale(0.5,0.5);
    }
}

@keyframes scaling {
    50% {
        transform: scale(0.5,0.5);
    }
}